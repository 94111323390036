/** @format */

import { useSelector } from "react-redux";
import { useDeleteEventSlot, useAddEventSlot } from "../apiCalls";
import { IconStar, LivetoLoader } from "components/icons";
import { LoadingOverlay } from "@mantine/core";
import { Localized } from "@fluent/react";

export const useManageMyCalendar = (slot, buttonClassName = "sidebar-handle-favourites", icon = null) => {
	const { loading, deleteEventSlot } = useDeleteEventSlot();
	const { loading: addLoading, addEventSlot } = useAddEventSlot();
	const { calendar = {} } = useSelector(state => state.schedule);
	const { slug = "" } = useSelector(state => state.event);
	const { guestuser } = useSelector(state => state.user);
	const { event_timeslots = [] } = calendar;

	if (guestuser) return ["", ""];

	const isIn = event_timeslots.map(et => et.id).includes(slot.id);

	const handleAddToFavorites = (e, isFavorite, slot) => {
		e.stopPropagation();
		try {
			if (isFavorite) {
				deleteEventSlot(slot, slug);
			} else {
				addEventSlot(slot, slug);
			}
		} catch (error) {
			console.error("Error", error);
		}
	};

	const isLoading = loading || addLoading;

	let button = isIn ? (
		<button
			className={buttonClassName}
			onClick={e => handleAddToFavorites(e, true, slot)}
			data-cy="remove-timeslot-btn"
		>
			{icon && icon}
			<Localized id="remove-favourite-from-calendar-button-text">
				<div className="w-100">Remove from your calendar</div>
			</Localized>
		</button>
	) : (
		<button
			className={buttonClassName}
			onClick={e => handleAddToFavorites(e, false, slot)}
			data-cy="add-timeslot-btn"
		>
			{icon && icon}
			<Localized id="add-favourite-to-calendar-button-text">Add to your calendar</Localized>
		</button>
	);

	let overlay = isLoading ? <LoadingOverlay loader={<LivetoLoader />} visible={isLoading} /> : "";

	return [button, overlay];
};
