/** @format */

import { useCallback, useEffect, useMemo, useState } from "react";
import "./css/calendar.scss";
import { Localized, useLocalization } from "@fluent/react";
import { connect } from "react-redux";
import { LvtModal, LvtMultiselect, LvtTooltip } from "components/LvtComponents";
import { culture, localizer, messages } from "./schedule";
import * as a from "actiontypes";
import { Calendar } from "react-big-calendar";
import SideBar from "./components/SideBar";
import CalendarAgendaEvent from "./components/CalendarEventAgenda";
import { generateColors, latestDate } from "components/lvtHelpers";
import moment from "moment";
import RBCCustomToolbar from "./components/RBCCustomToolbar";
import "moment/locale/en-gb";
import "moment/locale/fi";
import "moment/locale/sv";

const mapStateToProps = state => ({
	calendar: state.schedule.calendar,
	close_modals: state.close_modals,
	language: state.user.language,
	sidebar: state.components.sidebar,
	schedule: state.schedule.schedule,
	displayType: state.displayType,
});

const mapDispatchToProps = dispatch => ({
	closeModals: () => dispatch({ type: "HANDLE_MODALS", payload: true }),
	handleSidebar: payload => dispatch({ type: a.HANDLE_SIDEBAR, payload }),
});

const PASTEL_COLORS = {
	green: "#A1D6B2",
	orange: "#E8B86D",
	red: "#C96868",
	teal: "#7EACB5",
};

const STATUSES_NOT_TO_SHOW = ["rejected", "cancelled"];

const CalendarModal = props => {
	const {
		showCalendar,
		handleSidebar,
		sidebar,
		language,
		setShowCalendar,
		slug,
		calendar,
		close_modals,
		schedule = {},
		displayType,
	} = props;
	const { schedules = [] } = schedule;
	const colors = generateColors(schedules.length);
	const { l10n } = useLocalization();
	const schedulesWithColors = schedules.map((s, i) => ({ ...s, color: colors[i] }));

	const defaultView = displayType === "mobile" ? "day" : "week";

	const [minMax, setMinMax] = useState({
		min: new Date(1972, 0, 1, 0, 0, 0, 0),
		max: new Date(1972, 0, 1, 23, 59, 59),
	});
	const scheduleInfoText = (
		<div>
			<Localized id="calendar-info-text">
				<div>This is your personal calendar. It includes your favorite programs and scheduled meetings.</div>
			</Localized>
			<Localized id="schedule-info-text">
				<div>The times in this program are displayed according to your own time zone.</div>
			</Localized>
		</div>
	);

	useEffect(() => {
		if (close_modals) {
			close();
		}
	}, [close_modals]); // eslint-disable-line react-hooks/exhaustive-deps

	function close() {
		setShowCalendar(false);
	}

	const handleSelection = useCallback(event => {
		handleSidebar({ isOpen: true, slot: event.slot, slotRef: null });
	}, []);

	const { event_timeslots = [], slots = [], received_invites = [] } = calendar;

	const generateColor = ts => {
		switch (ts.status) {
			case "cancelled":
				return PASTEL_COLORS.red;
			case "pending":
				return PASTEL_COLORS.orange;
			case "accepted":
				return PASTEL_COLORS.green;
			case "busy":
				if (ts.sent_invites?.length > 0) {
					const { status } = ts.sent_invites[0];
					if (status === "accepted") return PASTEL_COLORS.green;
					else if (status === "pending") return PASTEL_COLORS.orange;
					else return PASTEL_COLORS.teal;
				} else return PASTEL_COLORS.teal;
			case "available":
				return PASTEL_COLORS.green;

			default:
				return PASTEL_COLORS.teal;
		}
	};

	// REMOVE EMPTY HOURS
	useEffect(() => {
		const combineTimeslots = [...event_timeslots, ...slots, ...received_invites.map(ri => ri.slot)];
		if (combineTimeslots.length > 0) {
			const startTimes = combineTimeslots.map(ts => new Date(ts.start_time).getHours());
			const endTimes = combineTimeslots.map(ts => new Date(ts.end_time).getHours());

			const earliestHour = Math.min(...startTimes);
			const latestHour = Math.max(...endTimes);

			const minTime = new Date(2024, 0, 1, earliestHour > 0 ? earliestHour - 1 : earliestHour, 0);
			const maxTime = new Date(2024, 0, 1, latestHour < 23 ? latestHour + 1 : latestHour, 0);

			setMinMax({ min: minTime, max: maxTime });
		}
	}, [JSON.stringify([...event_timeslots, ...slots, ...received_invites.map(ri => ri.status)])]);

	const [eventList, setEventList] = useState([]);
	const [filter, setFilter] = useState(["event", "personal"]);

	const eventTimeslotList = event_timeslots.map(ts => ({
		id: ts.id,
		title: `${ts.name} (${ts.schedule.name})`,
		start: new Date(ts.start_time),
		end: new Date(ts.end_time),
		color: schedulesWithColors.find(s => s.id === ts.schedule.id)?.color,
		slot: ts,
		type: "event",
	}));
	const timeslotList = slots
		.filter(s => s.sent_invites?.[0] && !STATUSES_NOT_TO_SHOW.includes(s.sent_invites?.[0]?.status))
		.map(ts => ({
			id: ts.id,
			title: l10n.getString(`status-${ts.name.toLowerCase()}`, null, ts.name),
			description: ts.description,
			start: new Date(ts.start_time),
			end: new Date(ts.end_time),
			color: generateColor(ts),
			slot: ts,
			type: "personal",
		}));
	const invitesTimeslots = received_invites
		.filter(i => !STATUSES_NOT_TO_SHOW.includes(i.status))
		.map(ri => {
			let { slot = {}, ...rest } = ri;
			return {
				id: slot.id,
				title: slot.name,
				description: slot.description,
				start: new Date(slot.start_time),
				end: new Date(slot.end_time),
				color: generateColor(ri),
				slot: { ...slot, ...rest },
				type: "personal",
			};
		});

	console.log({ received_invites, slots, invitesTimeslots });

	useEffect(() => {
		const mySlots = [...eventTimeslotList, ...timeslotList, ...invitesTimeslots];
		if (mySlots.length > 0) {
			const filteredEventList = mySlots.filter(e => filter.includes(e.type));
			setEventList(filteredEventList);
		}
	}, [JSON.stringify(filter)]);

	useEffect(() => {
		setEventList([...eventTimeslotList, ...timeslotList, ...invitesTimeslots]);
	}, [JSON.stringify(event_timeslots), JSON.stringify(slots), JSON.stringify(received_invites.map(ri => ri.status))]);

	const latest = latestDate(eventList.map(e => e.end));
	const calendarLength = moment(latest).diff(moment(), "days");

	const eventPropGetter = (event, start, end, isSelected) => {
		let backgroundColor = event.color || "lightblue"; // Default color
		let style = { backgroundColor, color: "#000" };

		return {
			style,
		};
	};

	return (
		<LvtModal
			classNames={{ header: "schedule-modal-header" }}
			opened={showCalendar}
			onClose={close}
			size="100%"
			title={
				<div className="schedule-header">
					<div className="schedule-title-container">
						<Localized id="calendar-navbar-mycal-link">
							<h2 className="schedule-title">My calendar</h2>
						</Localized>
						<LvtTooltip label={scheduleInfoText}>
							<div className="schedule-info-icon">
								<b>i</b>
							</div>
						</LvtTooltip>
					</div>
					<div className="actions">
						<LvtMultiselect
							data={[
								{
									value: "event",
									label: l10n.getString("calendar-filter-label-event", null, "Event timeslots"),
								},
								{
									value: "personal",
									label: l10n.getString("calendar-filter-label-personal", null, "Personal timeslots"),
								},
							]}
							onChange={setFilter}
							label={<Localized id="calendar-filter-label">Filter timeslots</Localized>}
							value={filter}
							clearable
							className="schedule-multiselect"
							data-cy="timeslot-dropdown"
						/>
					</div>
				</div>
			}
		>
			<div>
				<SideBar
					opened={sidebar.isOpen}
					//handleAddToFavorites={handleAddToFavorites}
					onClose={close}
				/>
				<Calendar
					localizer={localizer}
					events={eventList}
					startAccessor="start"
					endAccessor="end"
					style={{ height: "calc(100dvh - 135px)" }}
					eventPropGetter={eventPropGetter}
					onSelectEvent={handleSelection}
					messages={messages(true)[language]}
					defaultView={defaultView}
					length={calendarLength}
					components={{
						agenda: {
							event: CalendarAgendaEvent,
						},
						toolbar: RBCCustomToolbar,
					}}
					culture={culture[language]}
					{...minMax}
				/>
			</div>
		</LvtModal>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarModal);
