/** @format */

import { useEffect, useState, forwardRef } from "react";
import moment from "moment";
import "./css/calendar.scss";
import { Localized, useLocalization } from "@fluent/react";
import { connect } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useGenerateMultilingualData } from "../performers/helpers";
import * as a from "actiontypes";
import SideBar from "./components/SideBar";
import { LvtBox, LvtCloseButton, LvtModal, LvtMultiselect, LvtSelect, LvtTooltip } from "components/LvtComponents";
import "moment/locale/en-gb";
import "moment/locale/fi";
import "moment/locale/sv";
import { IconChevronLeft, IconChevronRight } from "components/icons";
import { earliestDate, generateColors, latestDate } from "components/lvtHelpers";
import CalendarAgendaEvent from "./components/CalendarEventAgenda";
import RBCCustomToolbar from "./components/RBCCustomToolbar";
export const localizer = momentLocalizer(moment);

export const messages = (is_calendar = false) => ({
	fi: {
		allDay: "Koko päivä",
		previous: <IconChevronLeft />,
		next: <IconChevronRight />,
		today: "Tänään",
		month: "Kuukausi",
		week: "Viikko",
		twodays: "2 päivää",
		schedules: "Ohjelmat",
		day: "Päivä",
		agenda: is_calendar ? "Kaikki tulevat" : "Koko ohjelma",
		date: "Päivämäärä",
		time: "Aika",
		event: "Tapahtuma",
		showMore: total => `+ Näytä lisää (${total})`,
		noEventsInRange: "Tällä aikavälillä ei ole tapahtumia.",
	},
	en: {
		allDay: "All day",
		previous: <IconChevronLeft />,
		next: <IconChevronRight />,
		today: "Today",
		month: "Month",
		week: "Week",
		twodays: "2 days",
		schedules: "Ohjelmat",
		day: "Day",
		agenda: is_calendar ? "All upcoming" : "Whole programme",
		date: "Date",
		time: "Time",
		event: "Event",
		showMore: total => `+ Show more (${total})`,
		noEventsInRange: "There are no events in this range.",
	},
	se: {
		allDay: "Hela dagen",
		previous: <IconChevronLeft />,
		next: <IconChevronRight />,
		today: "Idag",
		month: "Månad",
		week: "Vecka",
		twodays: "2 päivää",
		schedules: "Ohjelmat",
		day: "Dag",
		agenda: is_calendar ? "Alla kommande" : "Alla händelser",
		date: "Datum",
		time: "Tid",
		event: "Händelse",
		showMore: total => `+ Visa fler (${total})`,
		noEventsInRange: "Det finns inga händelser i detta intervall.",
	},
});

const mapDispatchToProps = dispatch => ({
	handleSidebar: payload => dispatch({ type: a.HANDLE_SIDEBAR, payload }),
});

const mapStateToProps = state => ({
	schedule: state.schedule.schedule,
	displayType: state.displayType,
	close_modals: state.close_modals,
	sidebar: state.components.sidebar,
	language: state.user.language,
	start_time: state.event.start_time,
	end_time: state.event.end_time,
});

export const culture = {
	fi: "fi",
	en: "en",
	se: "sv",
};

const Schedule = props => {
	const {
		showSchedule,
		setShowSchedule,
		sidebar,
		handleSidebar,
		slug,
		schedule = {},
		displayType,
		close_modals,
		language,
		end_time,
		start_time,
	} = props;
	const scheduleInfoText = (
		<Localized id="schedule-info-text">
			<div>The times in this program are displayed according to your own time zone</div>
		</Localized>
	);

	const { l10n } = useLocalization();

	const { generate } = useGenerateMultilingualData();

	const { schedules = [] } = schedule;

	const colors = generateColors(schedules.length);

	const [filteredSchedules, setFilteredSchedules] = useState(schedules.map(s => s.id));

	const [eventList, setEventList] = useState([]);
	const [eventListType, setEventListType] = useState("schedules");

	const defaultView = displayType === "mobile" ? "day" : "week";

	const [view, setView] = useState(defaultView);

	const [date, setDate] = useState(new Date());
	const [minMax, setMinMax] = useState({
		min: new Date(1972, 0, 1, 0, 0, 0, 0),
		max: new Date(1972, 0, 1, 23, 59, 59),
	});
	const eventLength = moment(end_time).diff(moment(start_time), "days");

	const isLive = (start, end) => {
		return moment().isBetween(moment(start), moment(end));
	};

	const mappedSchedules = schedules.map((s, i) => {
		const { timeslots = [] } = s;
		const start_time = earliestDate(timeslots.map(ts => ts.start_time));
		const end_time = latestDate(timeslots.map(ts => ts.end_time));

		return { ...s, start_time, end_time, color: colors[i] };
	});

	const scheduleList = mappedSchedules
		.filter(s => filteredSchedules.includes(s.id))
		.map((s, i) => ({
			id: s.id,
			title: s.name,
			start: new Date(s.start_time),
			end: new Date(s.end_time),
			color: s.color,
			data: s,
			type: "schedule",
		}));

	useEffect(() => {
		if (scheduleList) {
			setEventList(scheduleList);
		}
	}, [scheduleList?.length > 0]);

	useEffect(() => {
		if (eventListType.includes("timeslots")) {
			setEventList(slotList.filter(s => filteredSchedules.includes(s.scheduleId)));
		} else {
			setEventList(scheduleList.filter(s => filteredSchedules.includes(s.id)));
		}
	}, [JSON.stringify(filteredSchedules)]);

	useEffect(() => {
		if (view === "agenda") {
			setDate(new Date(start_time));
		} else {
			setDate(new Date());
		}
	}, [view]);

	const slotList = mappedSchedules
		.filter(s => filteredSchedules.includes(s.id))
		.reduce((prev, s, i) => {
			const { timeslots = [] } = s;
			const mapped = timeslots.map(ts => ({
				id: ts.id,
				title: generate(ts.display_name, ts.name),
				start: new Date(ts.start_time),
				end: new Date(ts.end_time),
				color: s.color,
				slot: ts,
				type: "slot",
				live: isLive(ts.start_time, ts.end_time),
				schedule: s.name,
				scheduleId: s.id,
			}));
			return [...prev, ...mapped];
		}, []);

	useEffect(() => {
		if (schedules.length > 0) {
			const combineTimeslots = schedules.reduce((prev, sched) => {
				return [...prev, ...sched.timeslots];
			}, []);

			const startTimes = combineTimeslots.map(ts => new Date(ts.start_time).getHours());
			const endTimes = combineTimeslots.map(ts => new Date(ts.end_time).getHours());

			const earliestHour = Math.min(...startTimes);
			const latestHour = Math.max(...endTimes);

			const minTime = new Date(2024, 0, 1, earliestHour - 1, 0);
			const maxTime = new Date(2024, 0, 1, latestHour + 1, 0);

			setMinMax({ min: minTime, max: maxTime });
		}
	}, [JSON.stringify(schedules)]);

	const eventPropGetter = (event, start, end, isSelected) => {
		let backgroundColor = event.color || "lightblue"; // Default color
		let style = { backgroundColor, color: "#000" };

		return {
			style,
		};
	};

	useEffect(() => {
		if (close_modals) {
			close();
		}
	}, [close_modals]);

	useEffect(() => {
		handleSidebar({ slot: null, isOpen: false, slotRef: null });
	}, []);

	function close() {
		setShowSchedule(false);
	}

	const handleSelection = event => {
		if (event.type === "schedule") {
			setFilteredSchedules([event.id]);
			setEventListType(["timeslots"]);
			setEventList(slotList.filter(s => s.scheduleId === event.id));
		} else {
			handleSidebar({ isOpen: true, slot: event.slot, slotRef: null });
		}
	};

	const handleFilterSchedules = val => {
		if (val.includes("all")) setFilteredSchedules(schedules.map(s => s.id));
		else {
			setFilteredSchedules(val);
		}
	};

	const selectOptions = () => {
		if (filteredSchedules.length === schedules.length)
			return schedules.map((s, i) => ({ value: s.id, label: s.name, backgroundColor: colors[i], color: "#000" }));
		else
			return [
				{ value: "all", label: "All shedules", backgroundColor: "#FFF", color: "#000" },
				...schedules.map((s, i) => ({ value: s.id, label: s.name, backgroundColor: colors[i], color: "#000" })),
			];
	};

	const SelectItem = forwardRef(({ image, label, description, color, backgroundColor, ...others }, ref) => (
		<div ref={ref} style={{ backgroundColor, color }} {...others}>
			{label}
		</div>
	));

	function Value({ value, label, onRemove, classNames, color, backgroundColor, ...others }) {
		return (
			<div style={{ color }} {...others}>
				<LvtBox
					sx={theme => ({
						display: "flex",
						cursor: "default",
						alignItems: "center",
						backgroundColor: backgroundColor,
						border: `1px solid ${
							theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[4]
						}`,
						paddingLeft: 10,
						borderRadius: 4,
					})}
				>
					<LvtBox sx={{ lineHeight: 1, fontSize: 12 }}>{label}</LvtBox>
					<LvtCloseButton
						onMouseDown={onRemove}
						variant="transparent"
						size={22}
						iconSize={14}
						tabIndex={-1}
						style={{ color }}
					/>
				</LvtBox>
			</div>
		);
	}

	const handleEventListType = val => {
		setEventListType(val);
		if (val === "schedules") setEventList(scheduleList);
		else setEventList(slotList);
	};

	const mobileViews = {
		month: false,
		day: true,
		agenda: true,
	};
	const desktopViews = {
		month: false,
		day: true,
		week: true,
		agenda: true,
	};

	const viewsProps = displayType === "mobile" ? mobileViews : desktopViews;

	return (
		<LvtModal
			opened={showSchedule}
			onClose={close}
			size="100%"
			title={
				<div className="schedule-header">
					<div className="schedule-title-container">
						<Localized id="calendar-navbar-schedule-link">
							<h2 className="schedule-title">Event Schedules</h2>
						</Localized>
						<LvtTooltip label={scheduleInfoText}>
							<div className="my-auto schedule-info-icon">
								<b>i</b>
							</div>
						</LvtTooltip>
					</div>
					<div className="actions">
						<LvtMultiselect
							data={selectOptions()}
							onChange={handleFilterSchedules}
							label={<Localized id="schedule-select-label">Schedules</Localized>}
							value={filteredSchedules}
							itemComponent={SelectItem}
							valueComponent={Value}
							clearable
							placeholder={l10n.getString("schedule-filter-placeholder", null, "Select schedules")}
							className="schedule-multiselect"
							classNames={{ dropdown: "schedule-multiselect-dropdown" }}
							data-cy="schedule-dropdown"
						/>
						<LvtSelect
							data={[
								{
									value: "timeslots",
									label: l10n.getString("calendar-checkbox-timeslots", null, "Timeslots"),
								},
								{
									value: "schedules",
									label: l10n.getString("calendar-checkbox-schedules", null, "Schedules"),
								},
							]}
							value={eventListType}
							onChange={handleEventListType}
							label={<Localized id="schedule-type-select-label" />}
						/>
					</div>
				</div>
			}
			classNames={{ header: "schedule-modal-header" }}
		>
			<div>
				<SideBar
					opened={sidebar.isOpen}
					//handleAddToFavorites={handleAddToFavorites}
					onClose={close}
				/>
				<Calendar
					localizer={localizer}
					culture={culture[language]}
					events={eventList}
					startAccessor="start"
					endAccessor="end"
					style={{ height: "calc(100dvh) - 150px" }}
					eventPropGetter={eventPropGetter}
					onSelectEvent={handleSelection}
					messages={messages(false)[language]}
					defaultView={defaultView}
					views={viewsProps}
					onNavigate={newDate => setDate(newDate)}
					date={date}
					onView={newView => setView(newView)}
					view={view}
					components={{
						agenda: {
							event: CalendarAgendaEvent,
						},
						toolbar: RBCCustomToolbar,
					}}
					length={eventLength}
					{...minMax}
				/>
			</div>
		</LvtModal>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
